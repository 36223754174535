export default function Boat() {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 866 237"
      style={{
        enableBackground: "new 0 0 866 237",
      }}
      xmlSpace="preserve"
    >
      <style type="text/css">
        {
          "\n\t.st0{fill:#525E9B;}\n\t.st1{fill:#FF0000;}\n\t.st2{fill:#FFF5AA;}\n\t.st3{fill:#48568C;}\n\t.st4{fill:#FAEA8F;}\n\t.st5{fill:#6069A2;}\n\t.st6{fill:#FFF19E;}\n\t.st7{fill:#FAE475;}\n\t.st8{fill:#ECD147;}\n\t.st9{fill:#FAEA8B;}\n\t.st10{fill:#F39E00;}\n\t.st11{fill:#7773D4;}\n\t.st12{fill:#E0F8E0;}\n\t.st13{fill:#C2F1C1;}\n\t.st14{fill:#FF9C00;}\n\t.st15{fill:#8A96A0;}\n"
        }
      </style>
      <path
        className="st0"
        d="M57.9,207.7c-13.4-8.1-22.9-19.3-28.4-34.1c-2.6-6.9-3.6-13.9-3.6-21.2c0-10.4,0.1-20.9,0.1-31.3 c0.2,0.7,0.4,1.3,0.5,2c1.7,14.1,7.4,26.4,17,36.8c12.9,14,29.2,20.9,48.1,21.4c15.2,0.3,30.3,0,45.5,0.1c2.6,0,3.5-0.9,3.5-3.5 c-0.1-7,0-14.1,0-21.1c2.6-0.1,5.2-0.2,7.8-0.3c1,0.1,2,0.2,3,0.3c0,6.9,0.1,13.9,0,20.8c-0.1,2.9,0.9,3.8,3.8,3.8 c12.4-0.1,24.9-0.1,37.3-0.1c0.2,1.3,0.3,2.6,0.5,3.9c0.8,5.5,4.9,9.5,10.4,10.6c7.6,1.5,16.4-4.5,15.6-14.5c20.4,0,40.9-0.1,61.3,0 c2.9,0,3.8-0.9,3.8-3.8c-0.2-6.8,0-13.6,0-20.4c0.4,0,0.9,0,1.3-0.1c1.8,0,3.5,0,5.2,0c0.5,0,1,0,1.5,0c0.9,0.1,1.8,0.1,2.7,0.2 c0,6.7,0.2,13.4,0,20.1c-0.1,3,0.9,4,3.9,4c15.1-0.1,30.3,0,45.4,0c0,0.6,0.1,1.2,0.1,1.8c0.3,8.5,7.4,14.2,15.7,12.6 c6.4-1.2,10.2-6.4,10.5-14.4c17.9,0,35.8-0.1,53.6,0c2.8,0,3.7-0.9,3.6-3.6c-0.2-6.8-0.1-13.6-0.1-20.5c0.3-0.1,0.6-0.1,1-0.2 c1.1,0,2.2,0,3.3,0c2.2,0.1,4.4,0.1,6.6,0.2c0,6.6,0,13.2,0,19.9c0,4.2,0,4.2,4.2,4.2c17.7,0,35.3,0,53,0c0,0.6,0,1.2,0.1,1.8 c0.2,8.4,7.5,14.2,15.8,12.6c6.5-1.2,10.1-6.2,10.4-14.4c15.2,0,30.5-0.1,45.7,0c2.7,0,3.7-0.8,3.7-3.6c-0.2-6.8,0-13.6,0-20.4 c2.8-0.1,5.6-0.1,8.5-0.2c0.8,0,1.6,0.1,2.4,0.1c0,6.6,0,13.3,0,19.9c0,4.2,0,4.2,4.2,4.2c20.3,0,40.6,0,60.9,0c0,0.5,0,1,0,1.5 c0.2,7.4,5.5,12.9,12.8,13.2c6.8,0.3,12.2-4.6,13.2-11.8c0.1-1,0.3-2,0.4-3c12.5,0,25.1-0.1,37.6,0.1c2.6,0,3.5-0.8,3.5-3.5 c-0.1-7,0-14.1,0-21.1l4.8-0.3c2,0.1,4,0.2,6,0.2c0,6.9,0.1,13.9,0,20.8c-0.1,2.9,0.8,3.9,3.8,3.8c14.5-0.1,28.9,0,43.4-0.1 c4.5-0.1,9.1-0.4,13.4-1.3c27.5-5.9,44.8-22.7,52.2-49.8c0.8-3,1.2-6.1,1.8-9.1c0,11.6,0.2,23.3,0,34.9c0,4.1-0.4,8.2-1.3,12.2 c-0.7,3-2.3,5.9-3.8,8.7c-2.7,5.2-5.4,10.5-8.6,15.5c-1.5,2.4-4,4.2-6.2,6c-4,3.4-8.1,6.6-12.1,9.9c-1.4-0.3-2.8-1-4.2-1 c-31.9-0.1-63.8,0-95.8,0c-48.1,0-96.2,0-144.3,0H275.4c-60.9,0-121.7,0-182.6,0c-10.3,0-20.7,0-31,0 C60.5,207.3,59.2,207.5,57.9,207.7L57.9,207.7z"
      />
      <rect
        x={177.9}
        y={85.4}
        className="fill-transparent hover:fill-yellow-200/30 "
        width={144.8}
        height={70.6}
      />
      <rect
        x={364}
        y={84.9}
        className="fill-transparent hover:fill-yellow-200/30 "
        width={84.9}
        height={76.1}
      />
      <rect
        x={502.6}
        y={86.7}
        className="fill-transparent hover:fill-yellow-200/30 "
        width={84.9}
        height={76.1}
      />
      <rect
        x={631.2}
        y={86.5}
        className="fill-transparent hover:fill-yellow-200/30 "
        width={55.8}
        height={76.1}
      />
      <path
        className="st2"
        d="M764.1,35.5c2.7-0.7,1.8,1.1,1.3,2.2c-3.1,6.5-5.7,13.4-9.6,19.4c-10.4,15.8-24.2,28.1-41.1,36.7 c-0.7,0.3-1.2,0.9-1.9,1.4c0,6.3,0,12.5,0,18.8c-10.3,0-20.5,0-30.8,0c0-3.6,0-7.2,0-10.8c0-5.5-2.2-10.5-5.1-14.9 c-4.1-6.4-11.5-6.8-18.1-8.1c-1.6-0.3-3.5,0.9-5.1,1.5c-4.7,1.7-8.5,4.5-10.7,9.1c-0.4,0.8-1.1,1.4-1.6,2.1c-0.6,1-1.5,2.2-1.5,3.3 c-0.1,5.9,0,11.9,0,17.8c-17,0-33.9,0-50.9,0c-1.4,0-2.8,0.4-4.1,0.6c1.1-3.2-0.6-5.8-1.4-8.8c-3.1-10.5-9.6-17.8-19.3-22.5 c-2-0.9-3.9-2.6-5.9-2.7c-6.4-0.4-12.8-0.6-19.2-0.3c-14.1,0.7-25,6.9-31,20.1c-1.9,4.2-2.2,9.1-3.3,13.6c-18.3,0-36.6-0.1-54.9-0.1 c-0.2-1.7-0.4-3.4-0.6-5.1c-1.7-12.6-11.8-24.4-24.2-27c-6.7-1.4-13.7-1.5-20.6-1.6c-18.8,0-33.8,14.8-34.3,33.7 c-18.7,0-37.4,0-56,0c-0.7-8.8-3.8-16.6-10-22.9c-7.2-7.2-16.3-10.6-26.3-10.8c-18.8-0.2-37.5-0.3-56.3,0 c-11.8,0.2-22.2,3.9-29.6,14.2c-4.3,5.9-6.9,12.1-6.9,19.4c-12.2,0-24.4,0-36.6,0.1c-0.7-7.2,0.3-13.9,6.5-18.9 c-3.2-1.7-6.1-3.1-8.8-4.8c-17.3-10.7-31.1-24.6-40.4-42.9c-1.6-3.1-2.7-6.5-3.9-9.8c-0.4-1-0.9-2.3,1.2-2 c12.1,7,24.3,13.8,36.1,21.2c5.4,3.4,10.9,4.2,17.1,4.2c187-0.1,374-0.1,560.9-0.1c1.8,0,3.6,0.2,5.4,0c1.5-0.2,3.2-0.5,4.5-1.3 c11.8-7.2,23.5-14.6,35.3-22C763,36.9,763.5,36.1,764.1,35.5L764.1,35.5z M152.3,63.3c-0.1,0.2-0.1,0.3-0.2,0.5 c3.5,2,7.1,3.9,10.5,6.2c3.4,2.4,7.1,2.8,11.1,2.8c33.4,0.1,66.9,0.4,100.4,0.5c39.8,0.2,79.6,0.4,119.4,0.6 c36.8,0.2,73.5,0.4,110.3,0.6c38.9,0.2,77.8,0.4,116.6,0.6c24.7,0.1,49.5-0.1,74.2,0.1c5,0,9.4-0.6,13.5-3.7c3.8-2.8,8-5.2,12-7.7 c-0.1-0.2-0.2-0.4-0.2-0.6L152.3,63.3L152.3,63.3z"
      />
      <path
        className="st3"
        d="M57.9,207.7c1.3-0.1,2.6-0.4,3.8-0.4c10.3,0,20.7,0,31,0c60.9,0,121.7,0,182.6,0h288.4c48.1,0,96.2,0,144.3,0 c31.9,0,63.8,0,95.8,0c1.4,0,2.8,0.6,4.2,1c-3.7,1.6-7.3,3.5-11.1,4.9c-7.9,2.9-16.2,4.3-24.7,4.3c-226,0-452,0-678,0 c-11.2,0-21.8-2-32-6.7C60.7,209.9,59.4,208.7,57.9,207.7L57.9,207.7z"
      />
      <path
        className="st4"
        d="M764.1,35.5c-0.6,0.7-1,1.5-1.7,1.9c-11.7,7.4-23.5,14.7-35.3,22c-1.3,0.8-3,1.1-4.5,1.3c-1.8,0.2-3.6,0-5.4,0 c-187,0-374,0-560.9,0.1c-6.1,0-11.6-0.7-17.1-4.2c-11.8-7.4-24-14.2-36.1-21.2c11.3,2.9,22.7,5.9,34.1,8.8 c7.9,2.1,15.7,4.2,23.6,6.2c1.4,0.3,2.8,0.6,4.1,0.6c12.9,0,25.9,0,38.8,0c1,0,2-0.2,2.9-0.4c1.2,0.3,2.5,0.8,3.7,0.8 c7,0.1,14.1,0,21.1,0c0.9,0,1.8,0.1,2.7,0.1c31.4,0,62.7,0,94.1,0c1,0,2-0.1,3-0.2c2.3,0,4.6,0,7,0.1c1.1,0,2.2,0.1,3.3,0.1 c58,0,115.9,0,173.9,0c2.1,0,4.2-0.1,6.3-0.1l6.9-0.1c1.6,0.1,3.2,0.2,4.8,0.2c26.2,0,52.3,0,78.5,0c8.1,0,16.2-0.1,24.4-0.1 c2.5,0,5,0.1,7.5,0c5.8-0.2,11.5-0.6,17.3-0.8c1.3,0.2,2.6,0.4,3.8,0.4c11.1,0,22.3,0.2,33.4-0.1c4.3-0.1,8.6-0.7,12.7-1.7 C728.8,44.9,746.4,40.1,764.1,35.5L764.1,35.5z"
      />
      <path
        className="st5"
        d="M839.9,121.1c-0.6,3-1,6.1-1.8,9.1c-7.4,27.1-24.7,43.9-52.2,49.8c-4.4,0.9-8.9,1.2-13.4,1.3 c-14.5,0.2-28.9,0-43.4,0.1c-2.9,0-3.8-1-3.8-3.8c0.2-6.9,0-13.9,0-20.8c23.8,0,47.6,0,71.4-0.2c2.5,0,5.5-0.8,7.5-2.3 c5.1-3.6,5.4-16.6,0.8-20.8c-1.8-1.7-7.3-2.3-9.4-0.9c-0.9,0.6-1.9,1.5-2.1,2.4c-0.8,3.6-1.3,7.3-2,11c-0.1,0.4-0.2,0.8-0.3,1.4 c-5.7-0.5-10.7-2.6-15-6.1c-8.9-7.4-11.8-17.1-9.4-28.3c2.5-11.4,12.3-20.6,24.7-20.9c20.7-0.4,41.4-0.1,62.1,0 c-7,1.9-12,6-13.5,13.2c-0.7,3.4-0.3,7-0.3,10.5C840,117.6,840,119.3,839.9,121.1L839.9,121.1z"
      />
      <path
        className="st5"
        d="M140.5,156.8c0,7-0.1,14.1,0,21.1c0.1,2.6-0.8,3.5-3.5,3.5c-15.2-0.1-30.3,0.2-45.5-0.1 c-18.9-0.4-35.2-7.4-48.1-21.4c-9.6-10.4-15.3-22.7-17-36.8c-0.1-0.7-0.3-1.3-0.5-2c-0.2-4.7-0.4-9.4-0.6-14.1 c-0.4-7.3-5.1-11.2-11.3-13.7c-0.7-0.3-1.4-0.6-2-1.2c1,0,2,0,3,0c18.7,0,37.4,0,56,0c16.6,0,29.1,12.7,28.3,29.2 c-0.7,13.7-10.2,23.4-23.2,25.8c-2,0.4-2.6-0.2-2.7-2.3c-0.1-2.6-0.5-5.3-1.1-7.9c-0.8-4-3.3-5.4-8.7-5.1c-2.9,0.2-5.6,2.9-5.8,6.3 c-0.2,3.2-0.2,6.4,0,9.6c0.4,5.4,4.7,8.9,10.6,8.9c22.6,0,45.2,0,67.8,0C137.7,156.7,139.1,156.8,140.5,156.8L140.5,156.8z"
      />
      <path
        className="st6"
        d="M327.7,33.5c-0.9-2.8-1.8-5.6-3-9.2c1.8,0.7,2.9,1,4,1.5c13.5,5.2,27.3,8.4,41.8,7.1c7.4-0.7,14.7-2.9,22.1-4.4 c12.9-2.8,25.9-4.4,39.1-3.9c13.5,0.5,26.7,2.5,39.8,5.5c20.5,4.6,40.5,1.7,60.2-4.4c1-0.3,2.1-0.6,3.9-1.2 c-1.2,3.5-2.1,6.3-3.1,9.2c-3.6,5.9-7.2,11.8-10.8,17.6c-1.3,0-2.6,0-3.9,0c-59.8,0-119.6,0-179.4,0c-1.6-2.4-3.3-4.8-5-7.1 c-0.5-1-1.1-2.1-1.7-3.1C330.4,38.3,329,35.9,327.7,33.5L327.7,33.5z M526.1,42.5c-0.1-0.2-0.2-0.4-0.3-0.6H334.5 c0,0.4-0.1,0.8-0.2,1.2c1.5,0.2,3,0.7,4.5,0.7c60.8,0,121.5,0,182.3,0c0.9,0,1.8,0.1,2.7-0.1C524.6,43.5,525.3,42.9,526.1,42.5 L526.1,42.5z"
      />
      <path
        className="st7"
        d="M636.3,51.6c-8.1,0-16.2,0.1-24.4,0.1c-26.2,0-52.3,0-78.5,0c-1.6,0-3.2-0.1-4.8-0.2c0.6-1.5,1.2-2.9,1.9-4.4 c2-4.5,4.1-9,6.2-13.4c18.9,0,37.8-0.5,56.7,0c16.8,0.5,33.1-1.3,49.2-6.5c10.9-3.5,22.1-6,33.2-8.9c1.1-0.3,2.2-0.4,3.2-0.6 c-0.2,0.3-0.4,0.7-0.7,0.9c-11.5,8.8-23.1,17.5-34.7,26.4C641.1,47.1,638.8,49.4,636.3,51.6L636.3,51.6z"
      />
      <path
        className="st7"
        d="M331.7,40.8c0.5,1,1.1,2.1,1.7,3.1c-1.7,0-3.4,0-5.7,0c1.5,3.1,2.5,5.4,3.5,7.6c-1,0.1-2,0.2-3,0.2 c-31.4,0-62.7,0-94.1,0c-0.9,0-1.8-0.1-2.7-0.1c-0.7-0.8-1.3-1.8-2.1-2.4c-9-6.9-18-13.7-27-20.6c-4.6-3.5-9.1-7.2-13.6-10.8 c7.1,1.8,14.2,3.5,21.3,5.4c12.1,3.2,24.2,6.4,36.3,9.6c1.8,0.5,3.7,0.8,5.6,0.8c22.2,0,44.3,0,66.5,0c1.4,0,2.8,0.1,4.2,0.1 c0.7,1.5,1.4,2.9,2.1,4.3C326.4,41.8,327.7,42.3,331.7,40.8L331.7,40.8z"
      />
      <path
        className="st2"
        d="M148.4,137.9c0-7,0-14.1,0-21.1c12.1,0,24.1,0,36.1,0c0,7.1,0,14.1,0,21.1C172.5,137.9,160.4,137.9,148.4,137.9 z"
      />
      <path
        className="st2"
        d="M151.3,156.8c-1-0.1-2-0.2-3-0.3c0-5.4,0.1-10.8,0.1-16.2c12,0,24.1,0,36.1,0c0,5.4,0,10.9,0.1,16.3 C173.5,156.7,162.4,156.8,151.3,156.8L151.3,156.8z"
      />
      <path
        className="st8"
        d="M188.7,17.8c4.5,3.6,9,7.2,13.6,10.8c9,6.9,18,13.7,27,20.6c0.8,0.6,1.4,1.6,2.1,2.4c-7,0-14.1,0.1-21.1,0 c-1.2,0-2.5-0.5-3.7-0.8c-7.9-8.8-14.3-18.4-18.1-29.7c-0.4-1-0.6-2.1-0.8-3.2C188,17.9,188.4,17.9,188.7,17.8L188.7,17.8 L188.7,17.8z"
      />
      <path
        className="st8"
        d="M636.3,51.6c2.4-2.1,4.7-4.4,7.3-6.4c11.5-8.9,23.1-17.6,34.7-26.4c0.3-0.2,0.5-0.6,0.7-0.9l0,0 c0.4,0,0.7,0,1.1,0c-3.3,12.7-10.5,23.1-18.9,32.8c-5.8,0.3-11.5,0.6-17.3,0.8C641.3,51.7,638.8,51.6,636.3,51.6L636.3,51.6z"
      />
      <path
        className="st4"
        d="M719.3,116.8c0,7,0,14.1,0,21.1c-2.2,0-4.4,0-6.5,0c0-7,0-14.1,0-21.1C715,116.8,717.1,116.8,719.3,116.8 L719.3,116.8z"
      />
      <path
        className="st4"
        d="M184.6,116.8c-12.1,0-24.1,0-36.1,0c0-1,0-2,0-3c12.2,0,24.4,0,36.6-0.1C184.9,114.8,184.7,115.8,184.6,116.8 L184.6,116.8z"
      />
      <path
        className="st9"
        d="M719.3,116.8c-2.2,0-4.4,0-6.5,0c-10.3,0-20.5,0-30.8,0l0-2.9c10.3,0,20.5,0,30.8,0c2.2,0.2,4.3,0.4,6.5,0.6 C719.3,115.2,719.3,116,719.3,116.8z"
      />
      <path
        className="st4"
        d="M719.4,156.5c-1.6,0.1-3.2,0.2-4.8,0.3c-0.6-0.1-1.2-0.2-1.8-0.2c0-5.4,0-10.8,0-16.3h6.6 C719.4,145.7,719.4,151.1,719.4,156.5L719.4,156.5z"
      />
      <path
        className="st4"
        d="M719.3,114.4c-2.2-0.2-4.3-0.4-6.5-0.6c0-6.3,0-12.5,0-18.8c4.9,3.8,6.9,8.8,6.6,14.9 C719.4,111.4,719.4,112.9,719.3,114.4L719.3,114.4z"
      />
      <path
        className="st8"
        d="M536.7,33.7c-2.1,4.5-4.1,9-6.2,13.4c-0.7,1.4-1.2,2.9-1.9,4.4c-2.3,0-4.6,0.1-6.9,0.1c0-0.2,0-0.3-0.1-0.5 c3.6-5.9,7.2-11.8,10.8-17.6C533.9,33.6,535.3,33.6,536.7,33.7z"
      />
      <path
        className="st9"
        d="M719.3,140.3c-2.2,0-4.4,0-6.6,0c-10.2,0-20.5,0-30.8,0c0-0.8,0-1.6,0-2.4c10.3,0,20.5,0,30.8,0 c2.2,0,4.4,0,6.5,0C719.3,138.7,719.3,139.5,719.3,140.3z"
      />
      <path
        className="st4"
        d="M184.5,140.3c-12,0-24.1,0-36.1,0c0-0.8,0-1.6,0-2.5c12,0,24.1,0,36.1,0C184.5,138.7,184.5,139.5,184.5,140.3 L184.5,140.3z"
      />
      <path
        className="st8"
        d="M331.7,40.8c-4,1.5-5.3,1-7-2.8c-0.6-1.5-1.4-2.9-2.1-4.3c1.7-0.1,3.4-0.1,5-0.2 C329,35.9,330.4,38.4,331.7,40.8z"
      />
      <path
        className="st7"
        d="M188.8,17.9c-0.4,0-0.8,0-1.1,0C188,17.9,188.4,17.9,188.8,17.9z"
      />
      <path
        className="st7"
        d="M680.1,17.9c-0.4,0-0.7,0-1.1,0C679.3,17.9,679.7,17.9,680.1,17.9z"
      />
      <path
        className="st5"
        d="M647,181.4c-20.3,0-40.6,0-60.9,0c-4.1,0-4.2,0-4.2-4.2c0-6.6,0-13.3,0-19.9c1-0.2,2-0.4,3-0.6 c18.3,0,36.6,0,54.9,0c1.7,0.1,3.4,0.2,5.1,0.3c1.9,0,3.7,0,5.6,0.1c2.8,0.1,5.6,0.2,8.5,0.3c0,4.2-0.1,8.4-0.1,12.5 C652.7,170.4,648.4,174.5,647,181.4L647,181.4z"
      />
      <path
        className="st5"
        d="M284,157.1c0,6.8-0.1,13.6,0,20.4c0.1,2.9-0.9,3.8-3.8,3.8c-20.5-0.1-40.9,0-61.3,0c-1-6-4.4-9.8-10.3-11.4 c0-4.2,0-8.4,0-12.7c1.8-0.2,3.6-0.5,5.4-0.7c0.6,0.2,1.2,0.4,1.8,0.5h5.3c0.6,0,1.3,0,1.9,0c4,0,7.9,0,11.9,0c0.7,0,1.3,0,2,0 c4,0,7.9,0,11.9,0c0.7,0,1.3,0,2,0c1.6-0.2,3.2-0.3,4.8-0.5c0.6,0.2,1.2,0.3,1.8,0.5c1.6,0,3.3,0,5-0.1c0.9,0,1.9,0,2.8,0 c1.5-0.1,2.9-0.3,4.4-0.4c0.6,0.2,1.2,0.3,1.9,0.5c4-0.2,8-0.3,12-0.5C283.7,156.8,283.9,157,284,157.1L284,157.1z"
      />
      <path
        className="st5"
        d="M495.6,181.4c-17.7,0-35.3,0-53,0c-4.2,0-4.2,0-4.2-4.2c0-6.6,0-13.2,0-19.9c2.2-0.1,4.4-0.1,6.6-0.2 c1.6-0.1,3.3-0.3,4.9-0.4c18.3,0,36.6,0,55,0c0.8,0.2,1.6,0.4,2.4,0.7c0,4.1-0.1,8.3-0.1,12.4C500.7,170.9,496.6,175,495.6,181.4 L495.6,181.4z"
      />
      <path
        className="st5"
        d="M427.5,157.2c0,6.8-0.1,13.7,0.1,20.5c0.1,2.7-0.8,3.7-3.6,3.6c-17.9-0.1-35.8,0-53.6,0c-1-6-4.5-9.7-10.3-11.4 c0-4.2-0.1-8.5-0.1-12.7c0-0.2,0-0.3,0-0.5c3.4,0,6.8-0.1,10.2-0.1c1.4,0.1,2.8,0.2,4.2,0.3c7.6,0,15.3,0.1,22.9,0.1 c1.2,0,2.5,0,3.7,0c1.9,0,3.9,0,5.8,0c2,0,3.9,0,5.8-0.1c0.3,0,0.6,0,0.9,0c0.4,0,0.8,0,1.2,0h1.2c1.6,0,3.2,0,4.8,0c1,0,2,0,3,0 c0.4,0,0.9,0,1.3,0l1.3,0C426.8,157.1,427.2,157.2,427.5,157.2L427.5,157.2z"
      />
      <path
        className="st5"
        d="M344.1,181.4c-15.1,0-30.3-0.1-45.4,0c-3,0-4-0.9-3.9-4c0.2-6.7,0-13.4,0-20.1c0.8-0.1,1.7-0.2,2.5-0.2 c0.9,0,1.9,0,2.8,0c1.5,0,2.9,0,4.4,0.1c0.6,0,1.3,0,1.9,0c1,0,2.1-0.1,3.1-0.1c1.6-0.1,3.2-0.2,4.7-0.3c13.9,0,27.7,0,41.6,0.1 c0,4.3,0,8.6,0,13C349.1,170.8,345.3,174.6,344.1,181.4L344.1,181.4z"
      />
      <path
        className="st5"
        d="M571.1,157.3c0,6.8-0.1,13.6,0,20.4c0.1,2.8-0.9,3.6-3.7,3.6c-15.2-0.1-30.5,0-45.7,0c-1-6.2-4.4-9.9-10.3-11.4 c0-4.2-0.1-8.5-0.1-12.7c2,0,4.1-0.1,6.1-0.1c2.8,0,5.6,0,8.4,0c0.3,0,0.7,0,1,0c13.4,0,26.8,0,40.2,0c0.3,0,0.7,0,1,0 C569.1,157.2,570.1,157.3,571.1,157.3L571.1,157.3z"
      />
      <path
        className="st5"
        d="M151.3,156.8c11.1-0.1,22.2-0.1,33.3-0.2c1.7,0.1,3.4,0.2,5.1,0.2c4.9,0.2,9.9,0.3,14.8,0.5l-0.2,12.4 c-6.9,0.8-10.3,5.2-11.9,11.6c-12.4,0-24.9-0.1-37.4,0.1c-2.9,0-3.9-0.9-3.8-3.8C151.4,170.7,151.3,163.7,151.3,156.8L151.3,156.8z"
      />
      <path
        className="st5"
        d="M712.8,156.6c0.6,0.1,1.2,0.2,1.8,0.2c0,7-0.1,14.1,0,21.1c0,2.6-0.9,3.5-3.5,3.5c-12.5-0.1-25.1-0.1-37.6-0.1 c-1.3-5.7-4.5-9.7-10.2-11.5c0-4.2,0-8.4,0-12.6c2.2-0.1,4.4-0.2,6.7-0.3c0.3,0,0.7,0,1.1,0.1l5.6,0.1c1.8-0.1,3.6-0.3,5.4-0.4 C692.2,156.6,702.5,156.6,712.8,156.6z"
      />
      <path
        className="st7"
        d="M507.2,157.3c-0.8-0.2-1.6-0.4-2.4-0.7c0-5.4,0-10.9,0-16.3c0-0.8,0-1.6,0-2.4c0-7,0-14.1,0-21.1c0-1,0-2,0-3 c1-4.6,1.4-9.4,3.3-13.6c5.9-13.2,16.8-19.4,31-20.1c6.4-0.3,12.8-0.1,19.2,0.3c2,0.1,3.9,1.8,5.9,2.7c9.8,4.7,16.3,12,19.3,22.5 c0.9,2.9,2.5,5.6,1.4,8.8c0,0.8,0,1.6,0,2.4c0,7,0,14.1,0,21.1c0,0.8,0,1.6,0,2.4c0,5.4,0,10.9,0,16.3c-1,0.2-2,0.4-3,0.6 c-0.8,0-1.6-0.1-2.4-0.1c0-0.2,0-0.3-0.1-0.5c0-3.2,0-6.4,0-9.6c0-0.7,0-1.4,0-2.2c-0.2-8.1-0.4-16.3-0.5-24.4 c0.2-0.4,0.4-0.7,0.5-1.1c2-16.7-8-30.8-23.9-33.7c-5.4-1-11-0.9-16.5-0.7c-21.3,0.8-32.6,18.3-29.7,37.9l0.2,22.2l0.1,0.1 c0,0.2-0.1,0.4-0.1,0.6c0,0.4,0,0.8,0.1,1.2c-0.2,3.3-0.5,6.5-0.7,9.8C508.3,156.9,507.8,157.1,507.2,157.3L507.2,157.3z"
      />
      <path
        className="st10"
        d="M306.4,157.1c-0.6,0-1.3,0-1.9,0c0-3.9,0-7.9,0-12c-1.8,0.1-3.1,0.1-4.8,0.2v11.3c-0.6,0-1.3-0.1-2-0.1v-11.2 h-5c-0.2,4-0.4,7.9-0.5,11.8c-0.5,0-1,0-1.5,0c0-3.9,0-7.8,0-11.7h-4.7c0,1.5,0.1,2.8,0,4c-0.1,2.6-0.4,5.1-0.6,7.7 c-0.4,0-0.9,0-1.3,0.1c-0.2-0.2-0.4-0.4-0.5-0.5c0-3.8,0-7.5,0-11.2h-5.2v11.4c-0.4,0-0.8,0-1.2,0v-11.4h-5c0,1.5,0.1,2.8,0,4 c-0.1,2.6-0.4,5.1-0.6,7.7c-0.6-0.2-1.2-0.3-1.9-0.5c0-3.7,0-7.5,0-11.2h-4.7v11.2c-0.6,0-1.3,0.1-2,0.1v-11.3h-4.9 c0,1.4,0,2.6,0,3.8c-0.1,2.7-0.4,5.3-0.5,8c-0.6-0.2-1.2-0.3-1.8-0.5c0-3.8,0-7.5,0-11.3h-4.8v11.8c-0.7,0-1.3,0-2,0 c0-3.9,0-7.8,0-11.8h-4.7c0,2.9,0.1,5.5-0.1,8.2c-0.1,1.1-0.7,2.2-1.1,3.3c-0.4-1.1-1.2-2.2-1.2-3.4c-0.2-2.6-0.1-5.3-0.1-8.1H237 v11.8c-0.7,0-1.3,0-2,0c0-3.9,0-7.8,0-11.8h-4.7c0,2.9,0.1,5.5-0.1,8.2c-0.1,1.1-0.7,2.2-1.1,3.3c-0.4-1.1-1.2-2.2-1.2-3.4 c-0.2-2.6-0.1-5.3-0.1-8.1h-4.8v11.8c-0.6,0-1.3,0-1.9,0c-0.3-3.9-0.6-7.9-0.9-11.7h-3.9c0,1.4,0.1,2.6,0,3.7 c-0.1,2.7-0.4,5.3-0.5,8c-0.6-0.2-1.2-0.4-1.8-0.5v0.1v-11.2h-4.6v11.2c-0.7,0-1.4,0-2.1,0v-11.3h-5.3v11.2l-1.9,0v-11.3h-5v11.3 c-0.7,0-1.4,0-2.1-0.1c0-2.9-0.2-5.8-0.1-8.6c0.1-2.2-0.6-3.2-2.9-2.7c0-1.1,0-2.1,0-3.2c1.3,0,2.6,0.1,3.9,0.1 c36.9,0,73.7,0,110.6,0c1.3,0,2.6,0.1,3.9,0.2c0,0.9,0,1.8,0,2.7c-1.8,0-2.6,0.6-2.5,2.6C306.5,150.8,306.4,153.9,306.4,157.1 L306.4,157.1z"
      />
      <path
        className="st11"
        d="M663.3,169.9c5.7,1.8,8.9,5.8,10.2,11.5c-0.1,1-0.3,2-0.4,3c-1,7.2-6.5,12.1-13.2,11.8 c-7.3-0.3-12.7-5.8-12.8-13.2c0-0.5,0-1,0-1.5c1.4-6.8,5.7-11,11.8-11.5c0,2.3-0.1,4.6-0.2,6.8c-1,0.6-2.2,1-3,1.8 c-2.6,2.5-2.6,6.4-0.2,8.9c2.3,2.4,6.6,2.5,9,0.3c2.6-2.4,3.1-6.6,0.6-8.8C662,176.1,662.6,173.1,663.3,169.9L663.3,169.9z"
      />
      <path
        className="st11"
        d="M192.4,181.3c1.6-6.3,5-10.7,11.9-11.6c0,2.3,0,4.6,0,6.9c-0.8,0.4-1.7,0.6-2.4,1.1c-2.8,2-3.4,6-1.4,8.8 c1.9,2.8,6,3.6,8.8,1.7c2.8-1.8,4.2-6.1,2-8.4c-3-3-2.8-6.4-2.6-10c5.8,1.6,9.2,5.4,10.3,11.4c0.8,9.9-8,16-15.6,14.5 c-5.5-1.1-9.6-5.1-10.4-10.6C192.7,183.9,192.6,182.6,192.4,181.3L192.4,181.3z"
      />
      <path
        className="st11"
        d="M511.4,170c5.9,1.4,9.3,5.2,10.3,11.4c-0.3,8.2-3.9,13.2-10.4,14.4c-8.3,1.6-15.5-4.3-15.8-12.6 c0-0.6,0-1.2-0.1-1.8c1-6.4,5.1-10.4,11.6-11.6c0,2.3,0,4.7,0,7c-0.6,0.2-1.1,0.4-1.7,0.6c-3,1.7-4.1,5.9-2.4,8.9 c1.7,2.9,6,3.9,9,2.2c3-1.8,4.4-6.3,1.9-8.8C511.1,176.6,511.3,173.4,511.4,170L511.4,170z"
      />
      <path
        className="st11"
        d="M344.1,181.4c1.2-6.7,4.9-10.5,11.6-11.6c0,2.3,0,4.6,0,7c-4.2,1.5-6,5.1-4.4,8.9c1.4,3.3,5.6,4.6,9,2.9 c3.3-1.7,4.8-6.3,2.1-9.2c-2.8-2.9-2.4-6.1-2.3-9.4c5.8,1.6,9.3,5.4,10.3,11.4c-0.3,8-4,13.2-10.5,14.4c-8.3,1.6-15.4-4.1-15.7-12.6 C344.2,182.6,344.2,182,344.1,181.4L344.1,181.4z"
      />
      <path
        className="st10"
        d="M431.8,157.1c-1.1,0-2.2,0-3.3,0c-0.5-0.1-1.1-0.1-1.6-0.2c0-19,0-37.9,0-56.9c1.9,1,3.7,2.1,5.6,3.1v42.2h12 c0,0.4,0.1,0.9,0.1,1.3c0,0.2-0.1,0.4-0.1,0.6h-12.1v9.6C432.1,156.8,432,156.9,431.8,157.1L431.8,157.1z"
      />
      <path
        className="st12"
        d="M431.8,157.1c0.2-0.2,0.4-0.3,0.5-0.5c4.2-0.2,8.4-0.4,12.6-0.6c0,0.4,0,0.7,0,1.1c-2.2,0.1-4.4,0.1-6.6,0.2 C436.2,157.2,434,157.1,431.8,157.1L431.8,157.1z"
      />
      <path
        className="st13"
        d="M579.4,156.6c0,0.2,0.1,0.3,0.1,0.5c-2.8,0.1-5.6,0.1-8.5,0.2c-1-0.1-2-0.1-3-0.2 C571.8,156.9,575.6,156.8,579.4,156.6L579.4,156.6z"
      />
      <path
        className="st2"
        d="M426.8,156.8c0.5,0.1,1.1,0.1,1.6,0.2c-0.3,0.1-0.6,0.1-1,0.2c-0.3-0.1-0.6-0.2-1-0.2 C426.6,156.9,426.7,156.9,426.8,156.8L426.8,156.8z"
      />
      <path
        className="st4"
        d="M152.3,63.3h567.5c0.1,0.2,0.2,0.4,0.2,0.6c-4,2.6-8.2,4.9-12,7.7c-4.1,3.1-8.6,3.7-13.5,3.7 c-24.7-0.2-49.5,0-74.2-0.1c-38.9-0.1-77.8-0.4-116.6-0.6c-36.8-0.2-73.5-0.4-110.3-0.6c-39.8-0.2-79.6-0.4-119.4-0.6 c-33.4-0.2-66.9-0.4-100.4-0.5c-4,0-7.7-0.4-11.1-2.8c-3.3-2.3-7-4.1-10.5-6.2C152.1,63.7,152.2,63.5,152.3,63.3L152.3,63.3z"
      />
      <path
        className="st7"
        d="M445,157.1c0-0.4,0-0.7,0-1.1c0-2.6,0-5.2,0-7.8l-0.4-1.7c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.3,0.1-0.5 c0.1-0.2,0.3-0.5,0.5-0.7c-0.1-7.6-0.2-15.3-0.3-22.9c-0.1-4.2,0.4-8.4-0.3-12.5C442.1,95,429,83.2,414.9,84.2 c-4.4,0.3-8.9-0.5-13.2,0.2c-4.5,0.8-9.3,2.1-13.2,4.4c-8.7,5.2-12.9,13.5-13.9,23.4c0.1,2.9,0.3,5.9,0.4,8.8 c-0.1,1.4-0.2,2.8-0.3,4.2c-0.1,4.7-0.2,9.4-0.2,14c0,1.7,0.4,3.5,0.5,5.2c0.1,0.1,0.1,0.3,0.1,0.4c-0.2,0.7-0.4,1.4-0.6,2l-0.1,10 c-1.4-0.1-2.8-0.2-4.2-0.3c0-4.5-0.1-9,0-13.5c0-2-0.7-2.9-2.8-2.9c-16.8,0-33.7,0.1-50.5,0c-2.1,0-2.8,0.9-2.8,2.9 c0.1,4.5,0,9,0,13.5c-1.6,0.1-3.2,0.2-4.7,0.3c-0.2-4-0.5-8-0.7-12c0-0.9,0-1.8,0-2.7c0.1-5.7,0.1-11.4,0.2-17.1 c0.1-0.2,0.1-0.3,0-0.5c0-0.8-0.1-1.7-0.1-2.5c1.6-9.2-0.2-17.8-5.9-25.2c-6.2-8.2-14.8-11.7-25.1-11.6c-19.4,0.2-38.8,0.2-58.1,0 c-8.6-0.1-15.5,2.8-21.4,8.8c-7.3,7.4-9.4,16.2-7.3,26.2c-0.2,7.2-0.4,14.5-0.6,21.7c0,1.1,0,2.1,0,3.2c0,3.8-0.1,7.6-0.1,11.4 c-0.2,0-0.4,0.1-0.5,0.2c-1.7-0.1-3.4-0.2-5.1-0.2c0-5.4,0-10.9-0.1-16.3c0-0.8,0-1.6,0-2.4c0-7.1,0-14.1,0-21.1c0.2-1,0.3-2,0.5-3 c0.1-7.3,2.6-13.4,6.9-19.4c7.4-10.3,17.8-13.9,29.6-14.2c18.8-0.4,37.5-0.3,56.3,0c10,0.1,19.1,3.5,26.3,10.8 c6.3,6.3,9.3,14.1,10,22.9c0,1,0,2.1,0,3.1c0,5.9,0,11.9-0.1,17.8c0,2.4,0.7,3.4,3.2,3.4c16.6-0.1,33.1-0.1,49.7,0 c2.6,0,3.3-1,3.2-3.4c-0.1-5.9-0.1-11.8-0.1-17.8c0-1,0-2,0-3.1c0.5-18.8,15.5-33.7,34.3-33.7c6.9,0,13.9,0.1,20.6,1.6 c12.4,2.6,22.5,14.5,24.2,27c0.2,1.7,0.4,3.4,0.6,5.1c0,1,0,2.1,0,3.1c0,7,0,14,0,21c0,0.8,0,1.7,0,2.5c0,5.4,0,10.8,0,16.2 C448.2,156.8,446.6,156.9,445,157.1L445,157.1z"
      />
      <path
        className="st7"
        d="M682,156.7c-1.8,0.1-3.6,0.3-5.4,0.4l-0.5-48.2c0-4.1,0.8-8.3,0-12.2c-1.7-7.7-9.2-12.3-17.8-11.2 c-10.2,1.3-16.5,12.3-12.5,22.2c-0.2,1-0.5,2-0.5,2.9c0,15.3,0,30.6,0,45.9c-0.2,0.1-0.3,0.2-0.4,0.4c-1.7-0.1-3.4-0.2-5.1-0.3 c0-1.2,0-2.4,0-3.6c0.2-4.2,0.4-8.5,0.6-12.7c0-0.8,0-1.6,0-2.5c-0.2-4.3-0.5-8.5-0.5-12.8c0-2.8,0.3-5.5,0.5-8.3 c-0.2-1-0.3-2-0.5-3c0-5.9-0.1-11.9,0-17.8c0-1.1,0.9-2.2,1.5-3.3c0.4-0.8,1.2-1.3,1.6-2.1c2.2-4.7,6-7.4,10.7-9.1 c1.7-0.6,3.6-1.8,5.1-1.5c6.6,1.3,14,1.7,18.1,8.1c2.8,4.4,5.1,9.3,5.1,14.9c0,3.6,0,7.2,0,10.8c0,1,0,2,0,2.9c0,7,0,14.1,0,21.1 c0,0.8,0,1.6,0,2.4C682,145.8,682,151.2,682,156.7L682,156.7z"
      />
      <path
        className="st4"
        d="M370.2,113.8c0,1,0,2,0,3.1c-18.7,0-37.3,0-56,0c0-1,0-2.1,0-3.1C332.9,113.8,351.5,113.8,370.2,113.8 L370.2,113.8z"
      />
      <path
        className="st9"
        d="M639.9,113.8c0.2,1,0.3,2,0.5,3c-18.5,0-37,0-55.5,0c0-0.8,0-1.6,0-2.4c1.4-0.2,2.8-0.6,4.1-0.6 C606,113.8,622.9,113.8,639.9,113.8L639.9,113.8z"
      />
      <path
        className="st9"
        d="M449.9,116.8c0-1,0-2.1,0-3.1c18.3,0,36.6,0.1,54.9,0.1c0,1,0,2,0,3C486.5,116.8,468.2,116.8,449.9,116.8z"
      />
      <path
        className="st7"
        d="M521.7,51.1c0,0.2,0,0.3,0.1,0.5c-2.1,0-4.2,0.1-6.3,0.1c-58,0-116,0-173.9,0c-1.1,0-2.2-0.1-3.3-0.1 c0-0.2,0.1-0.3,0.2-0.5c59.8,0,119.6,0,179.4,0C519.1,51.1,520.4,51.1,521.7,51.1L521.7,51.1z"
      />
      <path
        className="st8"
        d="M338.3,51c-0.1,0.2-0.1,0.3-0.2,0.5c-2.3,0-4.6,0-7-0.1c-1-2.2-2.1-4.5-3.5-7.6c2.3,0,4,0,5.7,0 C335,46.3,336.7,48.7,338.3,51z"
      />
      <path
        className="st9"
        d="M526.1,42.5c-0.8,0.4-1.5,1-2.3,1.2c-0.8,0.2-1.8,0.1-2.7,0.1c-60.8,0-121.5,0-182.3,0c-1.5,0-3-0.5-4.5-0.7 c0-0.4,0.1-0.8,0.2-1.2h191.3C526,42.1,526,42.3,526.1,42.5L526.1,42.5z"
      />
      <path
        className="st2"
        d="M682,137.9c0-7,0-14.1,0-21.1c10.3,0,20.5,0,30.8,0c0,7,0,14.1,0,21.1C702.5,137.9,692.3,137.9,682,137.9z"
      />
      <path
        className="st2"
        d="M682,156.7c0-5.4,0-10.9,0-16.3c10.2,0,20.5,0,30.8,0c0,5.4,0,10.8,0,16.3C702.5,156.6,692.3,156.6,682,156.7 L682,156.7z"
      />
      <path
        className="st2"
        d="M640.4,140.4c-0.2,4.2-0.4,8.5-0.6,12.7c0,1.2,0,2.4,0,3.6c-18.3,0-36.6,0-54.9,0c0-5.4,0-10.9,0-16.3 C603.4,140.3,621.9,140.3,640.4,140.4L640.4,140.4z"
      />
      <path
        className="st10"
        d="M670,157c-2.2,0.1-4.4,0.2-6.7,0.3c-0.6,0-1.2,0-1.8,0c-0.9,0-1.7,0-2.6,0c-2.8-0.1-5.6-0.2-8.5-0.3 c0-0.2,0-0.3,0-0.5l0.1-0.1l0.1,0.1c0.8,0,1.6,0,2.4,0c1,0,2,0,3,0c2.4-0.1,1.8-2,1.8-3.4c0-18.6,0-37.2,0-55.9 c1.8-0.1,3.6-0.3,5.5-0.4c0,18.1,0,36.1-0.1,54.2c0,3.4,1.7,5.3,5.2,5.6C669,156.7,669.5,156.9,670,157L670,157z"
      />
      <path
        className="st14"
        d="M658.9,157.3c0.9,0,1.7,0,2.6,0c0,6.4,0,12.7,0,19.1c0,0.8-0.7,1.6-1.1,2.4c-0.6-0.7-1.1-1.4-1.7-2.1 c0-2.3,0.1-4.6,0.2-6.8C658.8,165.7,658.9,161.5,658.9,157.3L658.9,157.3z"
      />
      <path
        className="st8"
        d="M650.4,156.5c0.1,0.2,0.1,0.3,0,0.5c-1.9,0-3.7,0-5.6-0.1c0.1-0.2,0.2-0.3,0.4-0.4 C647,156.6,648.7,156.5,650.4,156.5L650.4,156.5z"
      />
      <path
        className="st0"
        d="M208.7,169.9c-0.2,3.6-0.4,6.9,2.6,10c2.2,2.3,0.8,6.5-2,8.4c-2.9,1.9-6.9,1.1-8.8-1.7 c-1.9-2.8-1.4-6.8,1.4-8.8c0.7-0.5,1.6-0.7,2.4-1.1c0.5,0.7,1,1.4,1.6,2.2c0.4-0.8,1.1-1.6,1.1-2.4c0.1-6.2,0.1-12.4,0.1-18.6 c0.1-0.1,0.1-0.2,0.1-0.4c0.5-0.1,0.9-0.1,1.4-0.1C208.6,161.5,208.7,165.7,208.7,169.9L208.7,169.9z"
      />
      <path
        className="st8"
        d="M208.6,157.3c-0.5,0.1-0.9,0.1-1.4,0.1c-0.2-0.1-0.4-0.1-0.6-0.2c-0.7,0-1.4,0.1-2.2,0.1 c-4.9-0.2-9.9-0.3-14.8-0.5c0.2-0.1,0.3-0.2,0.5-0.2c1,0,2,0,3.1,0c0.7,0,1.4,0,2.1,0.1c1.6,0,3.3,0,5,0c0.6,0,1.2,0,1.9,0 c1.8,0,3.5,0,5.3,0c0.7,0,1.4,0,2.1,0c1.5,0,3.1,0,4.6,0v-0.1C212.3,156.8,210.5,157,208.6,157.3L208.6,157.3z"
      />
      <path
        className="st8"
        d="M264.9,156.6c0.1,0.2,0.2,0.3,0.4,0.5c-0.9,0-1.9,0-2.8,0c0.1-0.1,0.3-0.3,0.5-0.4 C263.6,156.6,264.2,156.6,264.9,156.6L264.9,156.6z"
      />
      <path
        className="st2"
        d="M449.9,156.6c0-5.4,0-10.8,0-16.2c18.3,0,36.6,0,54.9-0.1c0,5.4,0,10.9,0,16.3 C486.5,156.6,468.2,156.6,449.9,156.6L449.9,156.6z"
      />
      <path
        className="st10"
        d="M507.1,176.7c0-2.3,0-4.7,0-7c0-4.1,0.1-8.3,0.1-12.4c0.5-0.2,1-0.4,1.6-0.6c0.4,0.2,0.9,0.4,1.3,0.5 c0,6.4,0,12.7-0.1,19.1c0,0.8-0.8,1.6-1.1,2.4C508.2,178.1,507.7,177.4,507.1,176.7L507.1,176.7z"
      />
      <path
        className="st2"
        d="M314.2,156.7c0-4.5,0-9,0-13.5c0-2,0.7-2.9,2.8-2.9c16.8,0.1,33.7,0,50.5,0c2.1,0,2.8,0.8,2.8,2.9 c-0.1,4.5,0,9,0,13.5c-3.4,0-6.8,0.1-10.2,0.1c-0.4-0.1-0.9-0.1-1.3-0.1c-1.1-1.7-2-1.9-3,0.1C341.9,156.7,328.1,156.7,314.2,156.7 L314.2,156.7z"
      />
      <path
        className="st0"
        d="M360.1,170c-0.1,3.3-0.4,6.4,2.3,9.4c2.7,2.9,1.2,7.5-2.1,9.2c-3.4,1.7-7.5,0.3-9-2.9c-1.7-3.8,0.1-7.4,4.4-8.9 c0.5,0.7,1.1,1.4,1.6,2.1c0.4-0.8,1.2-1.6,1.2-2.4c0.1-6.4,0.1-12.8,0.2-19.1c0.4,0,0.8,0,1.2,0C360,161.5,360,165.8,360.1,170 L360.1,170z"
      />
      <path
        className="st13"
        d="M397.3,156.7c0,0.1,0,0.3,0,0.4c-7.6,0-15.3-0.1-22.9-0.1c0-3.3,0.1-6.7,0.1-10c0.2,0,0.4,0.1,0.5,0.2 c0,2.3,0,4.6-0.1,6.9c-0.1,1.9,0.8,2.7,2.7,2.6c4.1-0.1,8.2,0,12.3-0.1c0.7,0,1.5,0,2.2,0C393.9,156.7,395.6,156.7,397.3,156.7 L397.3,156.7z"
      />
      <path
        className="st2"
        d="M406.8,157.1c0-0.1,0.1-0.2,0.3-0.3c1.8,0.1,3.6,0.1,5.4,0.2l0.2,0.1C410.7,157,408.8,157,406.8,157.1 L406.8,157.1z"
      />
      <path
        className="st2"
        d="M416.1,157c0.2-0.1,0.4-0.3,0.6-0.4c1,0,2,0,3,0c0.4,0.1,0.8,0.3,1.2,0.5C419.3,157,417.7,157,416.1,157 L416.1,157z"
      />
      <path
        className="st2"
        d="M414.2,156.5c0.2,0.2,0.4,0.3,0.6,0.5c-0.4,0-0.8,0-1.2,0C413.8,156.9,414,156.7,414.2,156.5z"
      />
      <path
        className="st2"
        d="M425.1,156.6c0,0.1,0.1,0.3,0.1,0.4c-0.4,0-0.9,0-1.3,0C424.3,156.9,424.7,156.7,425.1,156.6L425.1,156.6z"
      />
      <path
        className="st15"
        d="M360,157.2c-0.4,0-0.8,0-1.2,0c0-0.2,0-0.4,0-0.6c0.4,0.1,0.9,0.1,1.3,0.1C360,156.9,360,157.1,360,157.2 L360,157.2z"
      />
      <path
        className="st10"
        d="M358.8,156.6c0,0.2,0,0.4,0,0.6c0,6.4,0,12.8-0.2,19.1c0,0.8-0.8,1.6-1.2,2.4c-0.5-0.7-1.1-1.4-1.6-2.1 c0-2.3,0-4.6,0-7c0-4.3,0-8.6,0-13C356.7,154.7,357.7,154.9,358.8,156.6L358.8,156.6z"
      />
      <path
        className="st8"
        d="M299.6,156.7c0.2,0.1,0.3,0.2,0.5,0.4c-0.9,0-1.9,0-2.8,0c0.1-0.2,0.2-0.3,0.4-0.5 C298.3,156.6,299,156.6,299.6,156.7L299.6,156.7z"
      />
      <path
        className="st0"
        d="M510.1,157.3c0.4,0,0.8,0,1.3,0c0,4.2,0.1,8.5,0.1,12.7c-0.1,3.4-0.3,6.7,2.5,9.6c2.5,2.5,1.1,7.1-1.9,8.8 c-3,1.8-7.3,0.8-9-2.2c-1.8-3-0.6-7.2,2.4-8.9c0.5-0.3,1.1-0.4,1.7-0.6c0.6,0.7,1.1,1.4,1.7,2.1c0.4-0.8,1.1-1.6,1.1-2.4 C510.1,170,510.1,163.7,510.1,157.3L510.1,157.3z"
      />
      <path
        className="st13"
        d="M566.9,156.9l0.2,0.2c-13.4,0-26.8,0-40.2,0l0.2-0.2c1.8-0.1,3.7-0.1,5.5-0.2c2.2,0,4.4,0,6.5-0.1 c1.8,0,3.7,0,5.5,0h4.1c0.8,0,1.7,0,2.5,0c3.4,0,6.8,0,10.2,0.1C563.2,156.8,565,156.8,566.9,156.9L566.9,156.9z"
      />
      <path
        className="st13"
        d="M517.5,157.1c2.8,0,5.6,0,8.4,0C523,157.1,520.3,157.1,517.5,157.1z"
      />
      <path
        className="st14"
        d="M204.5,157.3c0.7,0,1.4-0.1,2.2-0.1c0.2,0.2,0.3,0.4,0.5,0.6c0,6.2,0,12.4-0.1,18.6c0,0.8-0.8,1.6-1.1,2.4 c-0.5-0.7-1-1.4-1.6-2.2c0-2.3,0-4.6,0-6.9C204.4,165.6,204.5,161.5,204.5,157.3z"
      />
      <path
        className="st10"
        d="M671,157.1c0-16.2,0-32.5,0-48.7c0-1.2,0.1-2.4,0.1-3.6c0.4-0.4,0.8-0.9,1.2-1.3c1.3,1.8,2.5,3.7,3.8,5.5 l0.5,48.2C674.7,157.1,672.9,157.1,671,157.1L671,157.1z"
      />
      <path
        className="st0"
        d="M658.7,176.7c0.6,0.7,1.1,1.4,1.7,2.1c0.4-0.8,1.1-1.6,1.1-2.4c0.1-6.4,0-12.7,0-19.1c0.6,0,1.2,0,1.8,0 c0,4.2,0,8.4,0,12.6c-0.7,3.2-1.3,6.2,1.7,9c2.5,2.3,2,6.5-0.6,8.8c-2.4,2.2-6.7,2.1-9-0.3c-2.4-2.5-2.4-6.4,0.2-8.9 C656.5,177.7,657.6,177.3,658.7,176.7L658.7,176.7z"
      />
      <path
        className="st2"
        d="M584.9,116.8c18.5,0,37,0,55.5,0c-0.2,2.8-0.6,5.5-0.5,8.3c0,4.3,0.3,8.5,0.5,12.8c-18.5,0-37,0-55.5,0.1 C584.9,130.9,584.9,123.8,584.9,116.8z"
      />
      <path
        className="st2"
        d="M449.9,116.8c18.3,0,36.6,0,54.9,0c0,7,0,14.1,0,21.1c-18.3,0-36.6-0.1-54.9-0.1 C449.9,130.9,449.9,123.9,449.9,116.8L449.9,116.8z"
      />
      <path
        className="st9"
        d="M509.4,122.8c-2.9-19.6,8.4-37.1,29.7-37.9c5.5-0.2,11.1-0.3,16.5,0.7c15.9,2.9,25.9,17,23.9,33.7 c0,0.4-0.3,0.7-0.5,1.1c-2.6-6-5.5-11.7-11-15.7c-0.3-0.2-0.7-0.3-1-0.5c-1.8-1-3.7-2-5.5-3.1c-3.4-0.9-6.8-1.9-10.2-2.8 c-0.8,0-1.7-0.1-2.5-0.1c-1.4,0-2.8,0-4.2,0c-1.8,0-3.7,0-5.5,0c-2.2,0.4-4.4,0.8-6.6,1.1c-1.8,0.6-3.7,1.2-5.5,1.9 c-0.2,0-0.4,0-0.5,0.1c-2.4,1.6-4.9,2.9-7.1,4.8C514.1,110.5,511.4,116.5,509.4,122.8L509.4,122.8z"
      />
      <path
        className="st10"
        d="M509.5,145c1.2-0.1,2.4-0.2,3.6-0.4c1.8,0,3.6,0,5.4,0h8.3v-43c0.1-0.2,0.1-0.3,0.1-0.5 c1.8-0.6,3.7-1.2,5.5-1.9c0,19.1,0,38.2,0.1,57.4c-1.8,0.1-3.7,0.1-5.5,0.2c0-3.1,0-6.2,0-9.9c-6,0-11.7,0-17.5,0 c0-0.4,0-0.8-0.1-1.2c0.1-0.2,0.1-0.4,0.1-0.6L509.5,145L509.5,145z"
      />
      <path
        className="st10"
        d="M567,104.7v40.1c4.2,0,8.3,0,12.5,0c0,0.7,0,1.4,0,2.2h-12.6c0,3.5,0,6.6,0,9.8c-1.8-0.1-3.7-0.1-5.5-0.2 c0-18.5,0-37,0.1-55.5c1.8,1,3.7,2,5.5,3.1C566.9,104.4,566.9,104.6,567,104.7L567,104.7z"
      />
      <path
        className="st9"
        d="M584.9,137.9c18.5,0,37,0,55.5-0.1c0,0.8,0,1.6,0,2.5c-18.5,0-37,0-55.5-0.1 C584.9,139.5,584.9,138.7,584.9,137.9L584.9,137.9z"
      />
      <path
        className="st9"
        d="M449.9,137.9c18.3,0,36.6,0.1,54.9,0.1c0,0.8,0,1.6,0,2.4c-18.3,0-36.6,0-54.9,0.1 C449.9,139.5,449.9,138.7,449.9,137.9L449.9,137.9z"
      />
      <path
        className="st9"
        d="M509.6,145.2c0,0.2-0.1,0.4-0.1,0.6C509.5,145.5,509.6,145.4,509.6,145.2z"
      />
      <path
        className="st9"
        d="M426.9,99.9c-0.4-0.2-0.7-0.4-1.1-0.7c-2-0.4-4-0.8-6.1-1.2c-3-0.3-6.1-0.7-9.1-1c-1.7,0-3.4-0.1-5.1-0.1 c-4.6,0.2-9.2,0.4-13.2,3.1c-0.6,0.4-1.1,0.8-1.7,1.2c-0.4,0-0.8,0.1-1.2,0.1c-7.1,4.1-11.4,10.3-13.8,17.9 c-0.4-2.3-0.7-4.6-1.1-6.9c1-9.9,5.2-18.2,13.9-23.4c3.9-2.3,8.6-3.7,13.2-4.4c4.3-0.7,8.8,0.1,13.2-0.2c14.1-1,27.1,10.9,29.4,24.3 c0.7,4.1,0.2,8.3,0.3,12.5c-0.4-1-0.9-1.9-1.3-3c-2.1-6.1-5.8-11.1-10.9-15.1C430.6,102,428.7,100.9,426.9,99.9L426.9,99.9z"
      />
      <path
        className="st12"
        d="M444.9,148.2c-0.2-0.4-0.3-0.8-0.5-1.1c0-0.2,0.1-0.4,0.1-0.6C444.7,147,444.8,147.6,444.9,148.2L444.9,148.2z "
      />
      <path
        className="st9"
        d="M308.8,122.2c-1.4-3.9-2.8-7.9-6.2-10.8c-4.5-3.8-9.8-5.5-15.4-6c-4.2-0.4-8.4-0.6-12.6-0.6 c-18.1-0.1-36.3-0.2-54.5,0.1c-5.2,0.1-10.6,0.9-15.7,2.3c-6.8,1.8-11.6,6.2-13.5,13.3c-2.1-10-0.1-18.7,7.3-26.2 c6-6.1,12.9-8.9,21.4-8.8c19.4,0.2,38.8,0.2,58.1,0c10.4-0.1,18.9,3.4,25.1,11.6C308.6,104.4,310.4,113,308.8,122.2L308.8,122.2z"
      />
      <path
        className="st2"
        d="M314.2,116.8c18.7,0,37.3,0,56,0c0,5.9-0.1,11.9,0.1,17.8c0,2.4-0.6,3.4-3.2,3.4c-16.6-0.1-33.1-0.1-49.7,0 c-2.6,0-3.3-1-3.2-3.4C314.3,128.7,314.2,122.8,314.2,116.8L314.2,116.8z"
      />
      <path
        className="st10"
        d="M375.1,144.9h15c0-14.5,0-28.6,0-42.6c0.2,0,0.5-0.1,0.7-0.1c0.5,0.2,0.9,0.4,1.3,0.6v42.2h5.3 c1.2,0,2.3,0,3.5,0c0.5,0,1,0.1,1.5,0.1c3.1,0,4.6-1.4,4.6-4.5c0-13.1,0-26.2,0-39.3v-3.2c1.5-0.1,2.5-0.2,3.6-0.3 c2.2-0.1,1.9,1.5,1.9,2.9c0,16.4,0,32.8,0,49.2c0,2.3,0,4.6-0.1,6.9c-1.8-0.1-3.6-0.1-5.4-0.2c0-3.1,0-6.2,0-9.6h-14.9v9.5 c-0.7,0-1.5,0-2.2,0v-9.5h-15c-0.2-0.1-0.3-0.2-0.5-0.2C374.7,146.3,374.9,145.6,375.1,144.9L375.1,144.9z"
      />
      <path
        className="st12"
        d="M374.7,125.2c0.1,6.4,0.2,12.8,0.4,19.3c-0.2-1.7-0.5-3.5-0.5-5.2C374.5,134.6,374.6,129.9,374.7,125.2 L374.7,125.2z"
      />
      <path
        className="st12"
        d="M375.6,119.2c-0.2,0.6-0.4,1.2-0.7,1.9c-0.1-2.9-0.3-5.9-0.4-8.8C374.9,114.6,375.3,116.9,375.6,119.2 L375.6,119.2z"
      />
      <path
        className="st9"
        d="M309,125.2c-0.1-0.2-0.1-0.3,0-0.5C309,124.8,309.1,125,309,125.2z"
      />
      <path
        className="st12"
        d="M444.5,144.6c0.1-0.2,0.3-0.5,0.5-0.7C444.8,144.2,444.6,144.4,444.5,144.6z"
      />
      <path
        className="st9"
        d="M676.1,108.9c-1.3-1.8-2.5-3.7-3.8-5.5c-0.6-1-1.2-1.9-1.8-2.9c-2.4-1.2-4.8-2.4-7.2-3.6 c-1.8,0.1-3.6,0.3-5.5,0.4c-0.6,0.1-1.2,0.2-1.8,0.2c-1.2,0.6-2.5,1.1-3.7,1.7c-0.6,0.6-1.2,1.2-1.8,1.8c-0.4,0.2-0.8,0.3-1.2,0.5 c-1.2,2-2.5,4.1-3.7,6.1c-4-9.9,2.2-20.9,12.5-22.2c8.6-1.1,16.2,3.5,17.8,11.2C676.9,100.6,676.2,104.8,676.1,108.9L676.1,108.9z"
      />
      <path
        className="st10"
        d="M645.7,107.7c1.2-2,2.5-4.1,3.7-6.1c0.4,0,0.7,0.1,1.1,0.1c0,18.3,0.1,36.6,0.1,54.8l-0.1-0.1l-0.1,0.1 c-1.7,0-3.4,0-5.1,0.1c0-15.3,0-30.6,0-45.9C645.2,109.7,645.6,108.7,645.7,107.7L645.7,107.7z"
      />
      <path
        className="st15"
        d="M207.2,157.8c-0.2-0.2-0.3-0.4-0.5-0.6c0.2,0.1,0.4,0.1,0.6,0.2C207.3,157.6,207.3,157.7,207.2,157.8 L207.2,157.8z"
      />
      <path
        className="st2"
        d="M670.5,100.6c0.6,1,1.2,1.9,1.8,2.9c-0.4,0.4-0.8,0.9-1.2,1.3C671,103.3,670.7,101.9,670.5,100.6L670.5,100.6z"
      />
      <path
        className="st13"
        d="M567,104.7c-0.1-0.2-0.1-0.4-0.1-0.6c0.3,0.2,0.7,0.3,1,0.5C567.6,104.7,567.3,104.7,567,104.7z"
      />
      <path
        className="st13"
        d="M527,101.2c0,0.2,0,0.4-0.1,0.5c-0.1-0.1-0.3-0.3-0.4-0.4C526.6,101.2,526.8,101.2,527,101.2z"
      />
      <path
        className="st7"
        d="M392.4,100c-0.1,0.9-0.1,1.9-0.2,2.9c-0.5-0.2-0.9-0.4-1.3-0.6c0-0.4-0.1-0.7-0.1-1.1 C391.3,100.8,391.8,100.4,392.4,100L392.4,100z"
      />
      <path
        className="st13"
        d="M390.7,101.1c0,0.4,0.1,0.7,0.1,1.1c-0.2,0-0.5,0.1-0.7,0.1c-0.2-0.3-0.4-0.7-0.6-1.1 C389.9,101.2,390.3,101.2,390.7,101.1z"
      />
      <path
        className="st8"
        d="M650.7,101.1c0,0.2-0.1,0.4-0.1,0.6c-0.4,0-0.7-0.1-1.1-0.1C649.8,101.5,650.2,101.3,650.7,101.1z"
      />
    </svg>
  );
}
