import React from "react";
import { motion } from "framer-motion";
import gLink from "../Functions/Functions";
import { ArrowLeftOnRectangleIcon, HomeIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { BackButton, HomeButton } from "../Nav/buttons";
import { SlideshowLightbox } from "lightbox.js-react";
function Youtube() {
  const navigate = useNavigate();
  window.location.href = "https://www.youtube.com/@sagmayamiitgandhinagar870";
  return (
    <motion.div className="relative flex items-center flex-col bg-white w-full h-screen">
      {["bg-green-400", "bg-red-400", "bg-[#73A6FF]"].map((item, i, arr) => (
        <motion.div
          key={`anim.ab${i}`}
          initial={{ minHeight: "100%", width: "100%" }}
          animate={{
            minHeight: "0%",
            width: "100%",
            transition: { duration: 0.5, delay: (arr.length - i) * 0.2 },
          }}
          exit={{
            minHeight: "100%",
            width: "100%",
            transition: { duration: 0.5, delay: i * 0.2 },
          }}
          className={"absolute z-50 " + item}
        />
      ))}
      <div className="md:max-w-2xl mx-4 lg:max-w-4xl w-ull max-w-md flex items-center flex-col md:mx-auto ">
        <div className="flex flex-row items-center w-screen px-8 justify-between ">
          <HomeButton />
          <div className="flex flex-col items-center ">
            <h1 className="mt-4 text-sm font-black font-mont ">sagmayam</h1>
            <h1 className="mb-4 text-4xl font-black font-mont border-x-4 px-4 border-blue-400">
              YouTube
            </h1>
          </div>
          <BackButton />
        </div>
      </div>
    </motion.div>
  );
}

export default Youtube;
